import { useMemo } from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import Loading from "./Loading";

export default function Map({ lat = 43.629688, lng = -80.041489 }) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAGCb_EQlrJq8WboDl5sPbqd55uVlFw2mo",
  });
  if (!isLoaded)
    return (
      <div>
        <Loading />
      </div>
    );
  return (
    <GoogleMap
      zoom={2}
      center={{ lat, lng }}
      mapContainerClassName="map-container mt-9"
    >
      <MarkerF position={{ lat, lng }} />
    </GoogleMap>
  );
}
