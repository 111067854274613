import React, { useState, useEffect } from "react";
import axios from "axios";
import CITIES from "../staticData/city.list.json";
import ProgressBar from "./ProgressBar";
import Map from "./Map";
// import Loading from "./Loading.jsx";
import { randomNumber } from "../utilities/randomFunction";
import GuessForm from "./GuessForm";
import ThermShows from "./ThermShows";
import PointSys from "./PointSys";
import HowToPlay from "./HowToPlay";
import useCounter from "../hooks/useCounter";

const Weather = () => {
  const [startCount, resetCount, { counter }] = useCounter(25);

  const countMaxCityIndex = CITIES.length - 1;

  const [loading, setLoading] = useState(false);
  const [wfc, setWfc] = useState([]);

  const [cityPicker, setCityPicker] = useState();
  const [prevCity, setPrevCity] = useState();

  const [guess, setGeuss] = useState();
  const [difference, setDifference] = useState(50);

  const [dif, setDif] = useState();
  const [prevGuess, setPrevGuess] = useState();

  const [prevTemp, setPrevTemp] = useState();

  const [timer, setTimer] = useState(25);
  const [score, setScore] = useState(0);
  const [prevPoints, setPrevPoints] = useState("");
  // var newYearCountdown;

  const pointSys = {
    0: 200,
    1: 100,
    2: 50,
    3: 25,
    4: 10,
    5: -10,
    6: -20,
    7: -30,
    8: -40,
    9: -50,
  };

  useEffect(() => {
    if ([Math.abs(difference / 5 - 10)] > 9 || counter === "00:00:01") {
      setTimeout(() => {
        alert(
          `Game Over your final score is: ${score} : your thermometer shows ${
            difference / 5 - 10
          }°C`
        );
        window.location.reload();
      }, 2000);
    }
  }, [difference, counter, score]);

  useEffect(() => {
    if (cityPicker) {
      const URL = encodeURI(
        `https://api.openweathermap.org/data/2.5/weather?q=${cityPicker?.name}&country=${cityPicker?.country}&appid=0c3f6952253fc240a620719c0736773c&mode=json&units=metric`
      );
      axios.get(URL).then((res) => {
        setWfc(res?.data);
        setLoading(false);
      });
    }
  }, [cityPicker]);
  //let interval;
  const startGame = () => {
    const randomIndex = randomNumber(countMaxCityIndex);
    const cityChosen = CITIES[randomIndex];
    //  let timeRemaining = 20;
    // console.log(cityChosen);
    setCityPicker(cityChosen);
    setGeuss("");
    startCount();
  };
  var points;
  const handleClick = () => {
    resetCount();
    setTimeout(() => {
      startGame();
    }, 1000);

    var tempDif = Number(guess) - Number(Math.round(wfc?.main?.temp));
    if (Math.abs(tempDif) > 9) {
      points = -100;

      setScore(score + points);
    } else {
      points = pointSys[Math.abs(tempDif)];
      setScore(score + points);
    }

    setPrevPoints(points);
    setDif(tempDif);
    setPrevTemp(Number(Math.round(wfc?.main?.temp)));
    if (cityPicker) {
      setPrevCity(wfc?.name || "name not available");
    }
    setDifference(Number(difference) + tempDif * 5);

    // console.log(`difference= ${difference}`);
    // console.log(`tempdif= ${tempDif}`);

    setPrevGuess(guess);
  };

  return (
    <div className="w-[1480px] bg-[url('../images/desert/desert6.jpg')] relative">
      <div className="displayWeather float-left space-6 lg:space-x-12 mb-0">
        <HowToPlay cityPicker={cityPicker} startGame={startGame} />
      </div>

      <div>
        <div className="bg-slate-800 bg-opacity-60 ">
          <div>{counter}</div>
          <GuessForm
            wfc={wfc}
            handleClick={handleClick}
            guess={guess}
            setGuess={setGeuss}
            cityPicker={cityPicker}
            resetCount={resetCount}
          />
        </div>
        <div className={`${!cityPicker ? "hidden" : ""}`}>
          <div className="float-right mt-10">
            <div className="float-right border-2">
              <div className="border-2 p-5 h-[100px]  top-[20px] right-[20px] bg-slate-800 bg-opacity-60 ">
                <h5>Your Score : {score}</h5>
              </div>
              <div className="bg-slate-800 bg-opacity-60 ">
                <PointSys />
              </div>
            </div>
          </div>

          <div className={`box ${!cityPicker ? "hidden" : ""}`}>
            <div className="box ">
              <div>
                <div className=" w-[200px] float-left bg-slate-800 bg-opacity-60 m-3 ">
                  <ProgressBar
                    progress={Math.sign(difference) === -1 ? 0 : difference}
                  />
                </div>
                <div className="w-[900px] h-[500px] float-left pt-0 mt-0">
                  <Map
                    lng={Number(cityPicker?.coord?.lon || "-80.041489")}
                    lat={Number(cityPicker?.coord?.lat || "43.629688")}
                  />
                  <div className=" w-[900px] h-[150px] bg-slate-800 bg-opacity-90 mt-2 bottom-0 ">
                    <div
                      className={`${
                        !prevCity
                          ? "hidden"
                          : " border-[2px] text-[20px] p-1  m-2 justify-center"
                      }`}
                    >
                      {prevCity && prevTemp && prevGuess ? (
                        <h5
                          className={`${
                            prevTemp === prevGuess ? "animate-pulse" : ""
                          } text-[#909029] `}
                        >
                          The current tempurature in {prevCity} is {prevTemp},
                          Your guess was {prevGuess}, you were off by{" "}
                          <span
                            className={`text-[20px] ${
                              dif < 0
                                ? "text-[#9696e5]"
                                : dif > 0
                                ? "text-[red]"
                                : "text-[green]"
                            }`}
                          >
                            {dif}°C
                          </span>
                          <br />
                          You were awarded{" "}
                          <span
                            className={`text-[35px] border-2 p-1 ${
                              prevPoints > 0
                                ? "text-[green]"
                                : prevPoints < 0
                                ? "text-[red]"
                                : "text-[yellow]"
                            }`}
                          >
                            {prevPoints} points!
                          </span>
                        </h5>
                      ) : (
                        ""
                      )}

                      <p className="text-[green] text-center">
                        Keep your thermometer between 10°C and -10°C to continue
                        playing!
                      </p>
                      <div className=" w-[900px]">
                        <ThermShows
                          dif={dif}
                          difference={difference}
                          setDifference={setDifference}
                          setDif={setDif}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Weather;
