import React from "react";

export default function PointSys() {
  return (
    <div>
      <div className=" w-[350px] float-right p-5 bg-slate-800 bg-opacity-60 ">
        <p className="text-[20px]">
          Point system: <br />
          <span className="text-[yellow] ">
            Exact temperature: 200 points!
            <br />
          </span>
          <span className="text-[#3ff03f]">
            Off by 1 °C: 100pts!
            <br />
            Off by 2 °C: 50pts!
            <br />
            Off by 3 °C: 25pts!
            <br />
            Off by 4 °C: 10pts!
            <br />
          </span>
          <span className="text-[#ff2c2c]">
            Off by 5 °C: -10pts!
            <br />
            Off by 6 °C: -20pts!
            <br />
            Off by 7 °C: -30pts!
            <br />
            Off by 8 °C: -40pts!
            <br />
            Off by 9 °C: -50pts!
            <br />
            Off by 10°C or more: -100pts!
            <br />
          </span>
        </p>
      </div>
    </div>
  );
}
