import React from "react";

export default function ThermShows({ dif, difference, setDif, setDifference }) {
  return (
    <div className="text-center">
      <h5>
        Your Thermometer curently shows{" "}
        <span
          className={`text-[30px] text-center ${
            dif <= -5 / 5 - 10
              ? "text-[#9696e5]"
              : dif / 5 - 10 >= 5
              ? "text-[red]"
              : "text-[green]"
          }`}
        >
          {difference / 5 - 10}°C
        </span>
      </h5>
    </div>
  );
}
