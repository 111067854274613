import React from "react";

export default function GuessForm({
  handleClick,
  guess,
  setGuess,
  cityPicker,
  wfc,
  resetCount,
}) {
  return (
    <div>
      <form
        hidden={!cityPicker}
        onSubmit={(e) => {
          e.preventDefault();
          resetCount();
          handleClick();
        }}
      >
        Guess the current temperature in: {wfc?.name || "name not available"},{" "}
        {wfc?.sys?.country || " country not available"}
        <input
          value={guess}
          className="text-black  m-5 w-[100px]"
          type="text"
          placeholder={`   __°C`}
          onChange={(e) => {
            setGuess(e.target.value);
          }}
        />
        <button
          type="submit"
          className="pl-5 pr-5 border-2 transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300 ..."
          disabled={!cityPicker || !guess}
        >
          Final answer
        </button>
      </form>
    </div>
  );
}
