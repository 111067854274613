import logo from "./logo.svg";
import "./App.css";
import ProgressBar from "./components/ProgressBar";
import { useState } from "react";
import Weather from "./components/Weather";

function App() {
  const [temperature, setTempurature] = useState(0);

  return (
    <div className="App">
      <header className="App-header">
        <Weather />
      </header>
    </div>
  );
}

export default App;
