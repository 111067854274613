import React from "react";

export default function ProgressBar(props) {
  const progress = props.progress;
  // console.log(props);
  return (
    <div className=" flex justify-center items-center bg-[url('../images/thermometer.png')] h-[600px] w-[202px] ">
      <div className=" relative w-[20px] h-[500px] bg-[white] rounded-[20px]  overflow-hidden   mb-[40px]">
        <div
          style={{ height: `${progress}%` }}
          className={`duration-[2s] absolute bottom-0  w-full bg-[red] border=[0px] `}
        ></div>
      </div>
    </div>
  );
}
