import React from "react";

export default function HowToPlay({ cityPicker, startGame }) {
  return (
    <div className="bg-opacity-80 bg-slate-800">
      {!cityPicker ? (
        <p>
          {" "}
          How to play:
          <br />
          Guess the tempurature in the city indicated. <br />
          When you guess too high; your thermeometer will go up by the amount of
          degrees you were off. <br />
          If your geuss was too cold; your thermeometer will go down by the
          amount of degrees you were off. <br />
          If your thermeometer hits 10°C or -10°C you loose the game.
          <br />
          Point system: <br />
          <span className="text-[yellow]">
            If you guess the exact tempuarature you get 200 points!
            <br />
          </span>
          <span className="text-[green]">
            If you're off by 1 °C you get 100pts!
            <br />
            If you're off by 2 °C you get 50pts!
            <br />
            If you're off by 3 °C you get 25pts!
            <br />
            If you're off by 4 °C you get 10pts!
            <br />
          </span>
          <span className="text-[red]">
            If you're off by 5 °C you lose 10pts!
            <br />
            If you're off by 6 °C you lose 20pts!
            <br />
            If you're off by 7 °C you lose 30pts!
            <br />
            If you're off by 8 °C you lose 40pts!
            <br />
            If you're off by 9 °C you lose 50pts!
            <br />
            If you're off by 10°C or more, you lose 100pts!
            <br />
          </span>
          <button className="pl-5 pr-5 m-5 border-2" onClick={startGame}>
            Start
          </button>
        </p>
      ) : null}
    </div>
  );
}
